/* General App Styling */
@import url('https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&display=swap');
html {
  background-color: black;
}

.App {
  font-family: "Fondamento", serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  overflow: hidden;
  color: white;
  background-size: 100%;
 
}

.home-button {
  position: fixed; /* Keeps it in the top-left even when scrolling */
  top: 20px;
  left: 20px;
  width: 100px;
  height: 100px;
  z-index: 100; /* Ensures it's above other content */
}

.home-button img {
  width: 100px;
  height: 100px;
  object-fit: contain; /* Ensures the full icon is visible */
  cursor: pointer;
  transition: transform 0.2s ease;
}

.home-button img:hover {
  transform: scale(1.1); /* Adds a slight hover effect */
}

h1 {
  padding-top: 50px;
  font-size: 5rem;
  color: white;
}


/* FrontPage.css */
.fp {
  height: 150vh;
  min-height: 700px;
}
/* Background Layer Styling */
.fp-background-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;

}


.fp-background-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fp-background-left {
  width: 100%;
  height: 40vh;
  background-size: 290%;
  background-position: center 30%;
}

.fp-background-layer {
  display: block;
}


@media (min-width: 500px) {
  .fp-background-layer {
    flex-direction: row;
  }
  .fp-background-left {
    width: 50%;
    height: 100%;

  }
}

/* Content Layer Styling */


@media (min-width: 500px) {
  .content-layer {
    height: 100vh;
    z-index: 1;
    margin: auto;
    padding-top: 20vh;
  }

  .logo-container {
    height: 50vh; /* Height of the container */
    width: 100vw; /* Full width of the viewport */
    max-width: 500px;
    margin: auto;
    position: relative;
    overflow: hidden; /* Hide anything outside the container bounds */
    background-size:cover;  /* Ensure the image covers the entire container */
    background-position: center 42%; /* Keep the center of the image visible */
  }

  .fp-subtitle {
    transform: translateY(-50px);
    width: 100%;
    font-size: 2rem;
    color: white;
  }

  .fp-subtitle h2 {
    font-size: 2rem;
    margin-top: -100px;
    padding-bottom: 50px;
    color: white;
  }
}


@media (max-width: 500px) {
  .content-layer {
    height: 70vh;
    padding-top: 35vh;
  }

  .logo-container {
    height: 50vh; /* Height of the container */
    width: 100vw; /* Full width of the viewport */
    position: relative;
    overflow: hidden; /* Hide anything outside the container bounds */
    background-size:cover;  /* Ensure the image covers the entire container */
    background-position: center 42%; /* Keep the center of the image visible */
  }
  
  .fp-subtitle {
      transform: translateY(-20vh);
      width: 100%;
      font-size: 2em;
  }
  .fp-subtitle h2 {
    font-size: 2rem;
    margin-top: -50px;
    padding-bottom: 50px;
    color: white;
  }
}

@media (min-width: 1300px) {
  .fp-subtitle {
    transform: translateY(-40vh);
  }
}




/* Socials Section */
.socials-block {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials-block-content {
  margin: 0;
  position: relative;
  text-align: center;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 2em; /* Space between icons */
  font-size: 2em; /* Scale based on text size */
}

.social-icons a {
  color: #ffffff; /* Icon color */
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #0077b6; /* Change color on hover */
}

/* Responsive Adjustments */
@media (max-width: 500px) {
  .socials-block {
    height: 200px; /* Adjust height for smaller screens */
    padding: 20px 0;
  }
  .social-icons {
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 1em; /* Reduce spacing on small devices */
    font-size: 1.5em; /* Scale down */
  }
}

@media (max-width: 768px) {
  .d-flex.flex-column.flex-md-row {
    flex-direction: column;
  }
  .w-100.w-md-50 {
    width: 100% !important;
  }
}



/* ///////SECTIONS/////// */

.montage-container {
  position: relative; /* Ensures child elements can be absolutely positioned */
}

.montage-container img {
  height: 100vw;
}

.slaps-button {
  position: absolute; /* Places the button on top of the montage */
  top: 50%; /* Adjust vertical positioning */
  left: 50%; /* Adjust horizontal positioning */
  transform: translate(-50%, -50%) rotate(-20deg) scale(70%); /* Centers the button */
  z-index: 10; /* Ensures it's above other elements */
  cursor: pointer;
}

.sOne {
  background-size: cover;
}
.sectionText {
  width: 80%;
  margin: auto;
  padding-top: 20%;
  padding-bottom: 20%;
}

.gifgrid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

.gifgridimg-container {
  overflow: hidden;

}

.gifgridimg-container img {
  transform: scale(110%);
}
.sTwo {
  overflow: hidden;
  background-size: cover;
}

.sTwoBackground {
  margin-top: -5vw;
  background-size: 450%;
}

.bear-hand {
  margin-top: 10%;
  width: 35%;
}

.sTwo .sectionText {
  padding-top: 5%;
  padding-bottom: 5%; 
}

.sThree {
  height: fit-content;
  background-size: cover;
}

.goldloxe-forfeet-container {
  transform: translateX(-20%);
  overflow: hidden;
  background-position: bottom;
  background-size: 650px;
  background-repeat: no-repeat;
  width: 150%;
  height: 250px;
}

.goldloxe-forfeet {
  width: 80%;
  transform: translate(-10%,-75%);
}
.sThree .sectionText {
}

.sFour {
  padding-top: 200px;
  height: 500px;
  background-size: cover;
  background-position: center;
}

.sFour img {
  
  width: 200px;
}


/* ////// SLAPS ///// */

.Slaps {
  position: relative;
  width: 100vw;
  height: auto; /* Ensures height adjusts dynamically */
  overflow: hidden;

  color: black;
}

.Slaps .front {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensures it still takes up full viewport height */
  margin-top: -35vh; /* Pulls the section upwards without breaking the layout */
}

.Slaps .GraffitiWall {
  position: absolute;
  top: 0; /* Reset excessive negative shifting */
  left: 0;
  width: 100%;
  height: 150vh; /* Stretch to ensure it covers full area */
  object-fit: cover;
}

.Slaps .slaps-logo {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-20deg);
  width: 100vh;
  min-width: 200px;
  max-width: 100vw;
}

.Slaps .artworks {
  position: relative;
  width: 100%;
}

.Slaps .pale-background {
  object-fit: cover;
  position: absolute;
  display: block;
  height: auto;
}

.Slaps .content-layer {
  padding: 50px;
  position: relative;
  width: 100%;
  height: fit-content;
}

.Slaps h3 {
  padding-top: 100px;
}

.Slaps .mint-button {
  width: 75%;
  max-width: 250px;
}